<template>
    <div>
      <van-sticky :offset-top="0">
        <demo-block :title="('')">
          <van-nav-bar :title="('修改一卡通查询密码')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
            <template #right>
              <van-icon v-if="paytype === 0 || paytype === 1" name="wechat-pay" size="18" />
              <van-icon v-if="paytype === 2" name="alipay" size="18" />
            </template>
          </van-nav-bar>
        </demo-block>
      </van-sticky>
      <van-form @submit="onSubmit" style="margin-top: 9px;">
          <van-cell-group v-if="isbind===false || loginmode==='3'" inset >
            <van-field
              v-model="username"
              name="用户名"
              label="用户名"
              placeholder="用户名"
              readonly
              :rules="[{ required: true, message: '请填写用户名' }]"
            />
        </van-cell-group>
        <van-cell-group title="老密码" inset>
            <van-password-input
                :value="password"
                :focused="showKeyboard"
                :length="6"
                info="老密码为 6 位数字"
                @focus="showKeyboard = true"
                />
            <van-number-keyboard
                v-model="password"
                :show="showKeyboard"
                @blur="showKeyboard = false"
                />
        </van-cell-group>
        <van-cell-group title="新密码" inset>
                <van-password-input
                    :value="newpwd"
                    :focused="showKeyboardNewPwd"
                    :length="6"
                    info="新密码为 6 位数字"
                    @focus="showKeyboardNewPwd = true"
                    />
                <van-number-keyboard
                    v-model="newpwd"
                    :show="showKeyboardNewPwd"
                    @blur="showKeyboardNewPwd = false"
                    />
        </van-cell-group>
        <div v-if="isbind===true" style="margin: 16px;">
          <van-button :loading="isloading" round block type="primary" native-type="submit" loading-text="处理中...">
            修改密码
          </van-button>
        </div>
      </van-form>
      <br>
      <p align="center">
        <a href="https://beian.miit.gov.cn" id="beian" target="_blank"> {{this.$store.state.beian}}</a>
      </p>
    </div>
  </template>
  
  <script>
   import { GetBrowserType, IsBlank } from '../../utils/tools/index'
  import { Row, Col, Icon, Cell, CellGroup, Form, Field, Button, Popup, Sticky, NavBar, Area, Toast, Calendar, Radio, RadioGroup, NumberKeyboard,PasswordInput, Dialog } from 'vant';
  import { areaList } from '@vant/area-data';
  import { requestUserModifyPwd } from '../../api/api'
  import {  mapActions } from 'vuex'
  
  export default {
    components: {
      [Row.name]: Row,
      [Col.name]: Col,
      [Icon.name]: Icon,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [Form.name]: Form,
      [Field.name]: Field,
      [Button.name]: Button,
      [Popup.name]: Popup,
      [Sticky.name]: Sticky,
      [NavBar.name]: NavBar,
      [Area.name]: Area,
      [Calendar.name]: Calendar,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [NumberKeyboard.name]: NumberKeyboard,
      [PasswordInput.name]: PasswordInput
    },
    data() {
      return {
        isloading: false,
        loginmode: 3,
        showKeyboard:false,
        showKeyboardNewPwd: false,
        active: 0,
        showArea: false,
        showCalendar: false,
        userSex: '',
        userAddress: '',
        showSex: false,
        userArea: '',
        userbirthday: '',
        areaList: areaList,
        username: '',
        paytype: -1,
        password: '',
        newpwd: '',
        isbind: false
      }
    },
    watch: {
        password (val) {
          if (val.length >=6 ) {
            this.showKeyboardNewPwd = true
            this.showKeyboard = false
          }
        },
        newpwd (val) {
          if (val.length >=6 ) this.onSubmit()
        },
    },
    computed: {},
    mounted() {
      console.log('mount')
      console.log(this.getLocalLoginInfo())
      this.username = this.$store.state.userinfo.outid
      this.isbind = IsBlank(this.username) ===false
      this.paytype = GetBrowserType()
    },
    methods: {
    ...mapActions([
        'getLocalLoginInfo',
        'getIsNeedAuthLogin',
        'clearAccessToken',
        'getBaseUserInfo'
      ]),
    async onSubmit () {
        Dialog.confirm({
          title: '绑定确认',
          message: '您确认修改编号为' + this.username + '的一卡通账户查询密码？',
        })
          .then(async () => {
            this.isloading = true
            const res = await requestUserModifyPwd({loginmode: this.loginmode, accesstoken: this.$store.state.accesstoken, outid: this.username, pwd: this.password, newpwd: this.newpwd})
            if (res.return_code === '0') {
                Toast({
                    message: res.return_message ,
                    icon: 'success',
                })
                this.isloading = false
                // 更新accesstoken
                setTimeout(() => {
                    this.$router.push( '/index' +  '?t1=' + new Date().getTime());
                }, 500);
                    
            } else {
                Dialog.alert({
                    title: '错误提醒',
                    message: '修改密码失败：' + res.return_message,
                })
                setTimeout(() => {
                    this.isloading = false
                }, 1000);
                
            }
        })
      },
      OnSelectBirthday (day) {
        console.log(day)
        this.showCalendar = false
        this.userbirthday = `${day.getFullYear()}年${day.getMonth() + 1}月${day.getDate()}日`;
        this.$store.state.userbirthday = this.userbirthday
      },
      onConfirm (areaValues) {
        console.log(areaValues)
        this.showArea = false;
        this.userArea = areaValues
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
        console.log(this.userArea)
        this.$store.state.userArea = this.userArea
      }
    }
  };
  </script>
  
  <style lang="less">
  .user {
    &-poster {
      width: 100%;
      height: 53vw;
      display: block;
    }
  
    &-group {
      margin-bottom: 15px;
    }
  
    &-links {
      padding: 15px 0;
      font-size: 12px;
      text-align: center;
      background-color: #fff;
  
      .van-icon {
        display: block;
        font-size: 24px;
      }
    }
    
  }
  .img-icon {
        height: 20px;
        }
  </style>
  